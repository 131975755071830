import React, {useEffect} from "react";
import {projectPhone} from "../../../resources/url";

function ServiceOffered() {
    return (
        <section id="services-7" className="bg-lightgrey wide-70 servicess-section division">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1 section-title">
                        <h3 className="h3-md steelblue-color">Services Offered</h3>
                        <p>Comprehensive Medical Care and Specialized Services for Your Health and Well-being </p>

                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.4s">
                                    <a href="#">
                                        <span className="flaticon-137-doctor blue-color"></span>

                                        <div className="sbox-7-txt">
                                            <h5 className="h5-sm steelblue-color">Comprehensive Medical Examinations</h5>
                                            <p className="p-sm">For early detection and treatment of diseases.
                                            </p>

                                        </div>

                                    </a>
                                </div>
                            </div>


                            <div className="col-md-6">
                                <div className="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.6s">
                                    <a href="#">

                                        <span className="flaticon-076-microscope blue-color"></span>

                                        <div className="sbox-7-txt">

                                            <h5 className="h5-sm steelblue-color">Management of Chronic Conditions</h5>

                                            <p className="p-sm">Ongoing care for chronic diseases such as hypertension, diabetes,
                                                asthma, and arthritis.
                                            </p>

                                        </div>

                                    </a>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="sbox-7 icon-xs wow fadeInUp" data-wow-delay="0.8s">
                                    <a href="#">

                                        <span className="flaticon-065-hospital-bed blue-color"></span>

                                        <div className="sbox-7-txt">

                                            <h5 className="h5-sm steelblue-color">Diagnostic Testing</h5>

                                            <p className="p-sm">State-of-the-art laboratory and imaging services for accurate diagnosis.
                                            </p>

                                        </div>

                                    </a>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="sbox-7 icon-xs wow fadeInUp" data-wow-delay="1s">
                                    <a href="#">

                                        <span className="flaticon-058-blood-transfusion-2 blue-color"></span>

                                        <div className="sbox-7-txt">

                                            <h5 className="h5-sm steelblue-color">Acute Care Services</h5>

                                            <p className="p-sm">Treatment for sudden and severe medical conditions.
                                            </p>

                                        </div>

                                    </a>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="sbox-7 icon-xs wow fadeInUp" data-wow-delay="1.2s">
                                    <a href="#">

                                        <span className="flaticon-141-clinic-history blue-color"></span>

                                        <div className="sbox-7-txt">

                                            <h5 className="h5-sm steelblue-color">Lifestyle Counseling</h5>

                                            <p className="p-sm">Advice on diet, exercise, and lifestyle modifications to improve health outcomes.
                                            </p>

                                        </div>

                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-4">
                        <div className="sidebar-table blue-table sidebar-div mb-50">
                            <h5 className="h5-md">Working Time</h5>
                            <table className="table">
                                <tbody>
                                <tr>
                                    <td>Mon – Fri</td>
                                    <td> - </td>
                                    <td className="text-right">24 hours a day</td>
                                </tr>
                                <tr className="last-tr">
                                    <td>Sat - Sun</td>
                                    <td>-</td>
                                    <td className="text-right">24 hours a day</td>
                                </tr>
                                </tbody>
                            </table>

                            <h5 className="h5-xs">Need a personal health plan?</h5>

                            <p className="p-sm text-white"><a style={{color: '#FFFFFF !important'}} href={`tel: ${projectPhone}`}>{projectPhone}</a> </p>

                        </div>
                    </div>


                </div>
            </div>
        </section>
    )
}

export default ServiceOffered;