import Slider from "./section/slider";
import AboutSection from "./section/about";
import ServiceSection from "./section/service-section";
import ServiceTabSection from "./section/service-tab-section";
import CountSection from "./section/count-section";
import NewsSection from "./section/news-section";
import ContactSection from "./section/contact-section";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import ServiceOffered from "./section/service-offered";
import WhyChooseUs from "./section/why-choose-us";
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

function Homepage(props) {
    const [showSlider, setShowSlider] = useState(true);

    useEffect(() => {
        if (props.sliderList.length > 0) {
            window.scrollTo(0, 0);
            setShowSlider(false);
            setTimeout(() => setShowSlider(true), 0);
        }
        // Check if the page has already been reloaded
        setTimeout(()=>{
            if (!sessionStorage.getItem('reloaded')) {
                sessionStorage.setItem('reloaded', 'true');
                window.location.reload();
            }
        }, 3000)
    }, []);

    return(
    <>
        {showSlider && <Slider key={props.sliderList.length} sliderList={props.sliderList} />}
        <AboutSection />
        <ServiceSection serviceList={props.serviceList}/>
        <section id="info-3" className="bg-blue info-section division">
            <div className="container">
                <div className="row d-flex align-items-center">

                    <div className="col-lg-6">
                        <div className="info-3-img text-center">
                            <img className="img-fluid" src={require('../../images/tools.png')} alt="info-image" />
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="txt-block pc-30 white-color wow fadeInUp" data-wow-delay="0.4s">

                            <span className="section-id id-color"> Our Commitment</span>

                            <h3 className="h3-md">Solutions to Complex Medical Problems</h3>

                            <p className="text-justify">At Garkuwa Specialist Hospital, we are committed towards improving patient outcomes by delivering
                                compassionate, high-quality care tailored to each individual’s needs. Whether managing a chronic illness
                                or addressing acute medical concerns, we are here to help you on your journey to better health.
                            </p>

                            <Link to="/our-services" className="btn btn-tra-white white-hover mt-20">Find Out More</Link>

                        </div>
                    </div>


                </div>
            </div>
        </section>
        <ServiceOffered/>
        <ServiceTabSection/>
        <WhyChooseUs/>
        <NewsSection newsList={props.newsList}/>
        <ContactSection/>
    </>
)
}
const mapStateToProps = (state) => {
    return {
        departmentList: state.DepartmentListDetails,
        newsList: state.NewsDetails,
        sliderList: state.SliderDetails,
        galleryList: state.GalleryDetails,
        serviceList: state.ServiceDetails,
    };
};
export default connect(mapStateToProps, null)(Homepage);
