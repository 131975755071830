import {useEffect} from "react";

function WhyChooseUs() {
    return (
        <section id="info-6" className="bg-blue info-section division">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="side-img col-md-6 text-center "
                         style={{
                             backgroundImage: `url(${require('../../../images/reception.png')})`,
                             backgroundSize: 'cover',
                             backgroundPosition: 'center',
                             height: '500px'
                         }}
                    >
                    </div>
                    <div className="col-lg-6">
                        <div className="txt-block pc-30 white-color wow fadeInUp" data-wow-delay="0.4s">

                            {/*<span className="section-id id-color">Why Choose Garkuwa Specialist Hospital?</span>*/}

                            <h3 className="h3-md">Why Choose Garkuwa Specialist Hospital?</h3>

                            <div className="box-list">
                                <div className="box-list-icon"><i className="fas fa-genderless"></i></div>
                                <p className="text-justify"> Expertise: Our internists are skilled in the latest medical advancements and diagnostic techniques to
                                    offer you personalized and evidence-based care.
                                </p>
                            </div>

                            <div className="box-list">
                                <div className="box-list-icon"><i className="fas fa-genderless"></i></div>
                                <p className="text-justify">Collaborative Care: We work closely with other departments involving relevant specialities at all times
                                    in order to ensure comprehensive management of multi-system diseases.
                                </p>
                            </div>

                            <div className="box-list">
                                <div className="box-list-icon"><i className="fas fa-genderless"></i></div>
                                <p className="text-justify">Preventive Care: We emphasize the importance of preventive medicine, offering routine check-ups,
                                    screenings, and health education to help patients manage risk factors and prevent disease
                                </p>
                            </div>
                            <div className="box-list">
                                <div className="box-list-icon"><i className="fas fa-genderless"></i></div>
                                <p className="text-justify">Patient-Centered Approach: At Garkuwa, we believe in involving our patients in their healthcare
                                    decisions, offering a holistic approach that addresses not only physical but also emotional well-being.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>




        </section>
    )
}

export default WhyChooseUs;